import { DatePicker, Tooltip } from 'antd'
import React, { useState } from 'react'
import { updateData } from '../../resources';

// FATHER: detalles.paciente.jsx

export default function FechaNacimientoEdit({ fecha_nacimiento, id_paciente, getDataFn }) {
    const [isEditing, setIsEditing] = useState(false)

    const onChange = (date, dateString) => {
        console.log(id_paciente)
        console.log(date, dateString);
        updateData(`users/updateUser/${id_paciente}`, { fecha_nacimiento: date }).then((rs) => {
            if (getDataFn) getDataFn()
            setIsEditing(false)
        })
    };

    return (
        isEditing ?
            <div >
                <DatePicker format='DD/MM/YYYY' onChange={onChange} />
            </div>
            :
            <Tooltip title='Presione para editar fecha de nacimiento' >
                <div onClick={() => setIsEditing(true)}>
                    <span style={{ fontSize: 10, fontWeight: 600 }}>Fecha de nacimiento</span>
                    <p className='datos'>{fecha_nacimiento ? new Date(fecha_nacimiento).toLocaleDateString() : "-"}</p>
                </div>
            </Tooltip>
    )
}
